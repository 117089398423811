// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-topic-david-videos-mdx": () => import("./../../../src/pages/topic/david/videos.mdx" /* webpackChunkName: "component---src-pages-topic-david-videos-mdx" */),
  "component---src-pages-topic-david-writings-mdx": () => import("./../../../src/pages/topic/david/writings.mdx" /* webpackChunkName: "component---src-pages-topic-david-writings-mdx" */),
  "component---src-pages-topic-japanese-verbs-mdx": () => import("./../../../src/pages/topic/japanese/verbs.mdx" /* webpackChunkName: "component---src-pages-topic-japanese-verbs-mdx" */),
  "component---src-pages-topic-japanese-vocab-time-and-date-mdx": () => import("./../../../src/pages/topic/japanese/vocab/time-and-date.mdx" /* webpackChunkName: "component---src-pages-topic-japanese-vocab-time-and-date-mdx" */),
  "component---src-pages-topic-programming-sheets-formulae-mdx": () => import("./../../../src/pages/topic/programming/sheets-formulae.mdx" /* webpackChunkName: "component---src-pages-topic-programming-sheets-formulae-mdx" */),
  "component---src-pages-topic-suggestions-video-games-monochrome-mdx": () => import("./../../../src/pages/topic/suggestions/video-games/monochrome.mdx" /* webpackChunkName: "component---src-pages-topic-suggestions-video-games-monochrome-mdx" */),
  "component---src-pages-topic-travel-croatia-index-mdx": () => import("./../../../src/pages/topic/travel/croatia/index.mdx" /* webpackChunkName: "component---src-pages-topic-travel-croatia-index-mdx" */),
  "component---src-pages-topic-travel-japan-index-mdx": () => import("./../../../src/pages/topic/travel/japan/index.mdx" /* webpackChunkName: "component---src-pages-topic-travel-japan-index-mdx" */),
  "component---src-pages-topic-video-games-diy-gb-carts-mdx": () => import("./../../../src/pages/topic/video-games/diy-gb-carts.mdx" /* webpackChunkName: "component---src-pages-topic-video-games-diy-gb-carts-mdx" */),
  "component---src-pages-topic-video-games-exapunks-mdx": () => import("./../../../src/pages/topic/video-games/exapunks.mdx" /* webpackChunkName: "component---src-pages-topic-video-games-exapunks-mdx" */),
  "component---src-pages-topic-video-games-monster-hunter-rise-index-mdx": () => import("./../../../src/pages/topic/video-games/monster-hunter-rise/index.mdx" /* webpackChunkName: "component---src-pages-topic-video-games-monster-hunter-rise-index-mdx" */),
  "component---src-pages-topic-video-games-nintendo-switch-online-index-mdx": () => import("./../../../src/pages/topic/video-games/nintendo-switch-online/index.mdx" /* webpackChunkName: "component---src-pages-topic-video-games-nintendo-switch-online-index-mdx" */),
  "component---src-pages-topic-video-games-tis-100-mdx": () => import("./../../../src/pages/topic/video-games/tis-100.mdx" /* webpackChunkName: "component---src-pages-topic-video-games-tis-100-mdx" */)
}

